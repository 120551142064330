import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.jsx";
import Body from '../../components/Body';
import Headline from '../../components/Body/Headline';
import SocialButtons from '../../components/SocialButtons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Body withoutHero mdxType="Body">
  <Headline level="1" offsetLeft standalone mdxType="Headline">Contact</Headline>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Enquiries : `}<inlineCode parentName="p">{`contact@mvlouisemichel.org`}</inlineCode><br />{`
Press Enquiries : `}<inlineCode parentName="p">{`press@mvlouisemichel.org`}</inlineCode><br />{`
Crew applications: `}<inlineCode parentName="p">{`crewing@mvlouisemichel.org`}</inlineCode><br />{`
Shop Enquiries: `}<inlineCode parentName="p">{`shop@mvlouisemichel.org`}</inlineCode><br /><br /></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Official Social Media Accounts:`}<br /></p>
          <SocialButtons mdxType="SocialButtons" />
        </li>
      </ul>
    </Body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      